<template>
  <v-col sm="6" md="4" lg="2">
    <v-autocomplete
      item-text="label"
      item-value="key"
      :items="availableAttributeLabels"
      :label="label"
      v-model="itemValue"
      menu-props="closeOnContentClick"
      hide-no-data
      hide-selected
      name="status"
      @input="handleInput"
    >
      <v-icon
        slot="append"
        :title="$t('removeFilter')"
        @click="$emit('remove')"
      >
        mdi-close
      </v-icon>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AttributeSearch",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: String,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
      closeOnContentClick: true,
    };
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
  },
  computed: {
    ...mapGetters("account/donor", ["availableAttributes"]),
    availableAttributeLabels: function () {
      let result = [];
      const vm = this;
      this.availableAttributes.forEach(function (attribute) {
        result.push({
          key: attribute,
          label: vm.$t("account.attribute")[attribute],
        });
      });
      return result;
    },
  },
};
</script>
