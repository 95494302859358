<template>
  <v-col sm="6" md="4" lg="2">
    <v-autocomplete
      item-text="label"
      item-value="key"
      :items="availableScopeLabels"
      :label="label"
      v-model="itemValue"
      menu-props="closeOnContentClick"
      deletable-chips
      multiple
      small-chips
      hide-no-data
      hide-selected
      name="status"
      @input="handleInput"
    >
      <v-icon
        slot="append"
        :title="$t('removeFilter')"
        @click="$emit('remove')"
      >
        mdi-close
      </v-icon>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ScopeSearch",
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
      type: Array,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
      closeOnContentClick: true,
    };
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
  methods: {
    handleInput: function () {
      this.$emit("input", this.itemValue);
    },
  },
  computed: {
    ...mapGetters("service", ["items"]),
    availableScopeLabels: function () {
      let result = [];
      this.items.forEach(function (item) {
        result.push({
          key: item.scope,
          label: item.title,
        });
      });
      return result;
    },
  },
};
</script>
