<template>
  <v-sheet outlined class="mt-1">
    <v-row class="pa-3 pl-4 align-center">
      <v-col sm="2" md="3" lg="2" class="align-center">
        <v-icon>mdi-{{ targetIcon }}</v-icon>
        <router-link
          class="text-decoration-none grey--text text--darken-4"
          :to="{
            name: 'AccountPage',
            params: {
              gobellsId: accountHistory.gobellsId,
              scope: accountHistory.target,
              userId: accountHistory.userId,
            },
          }"
        >
          <span class="caption">
            {{ accountHistory.account.userId || "-" }}
          </span>
        </router-link>
      </v-col>
      <v-col sm="2" md="2" lg="2">
        <div class="caption text-center" :style="{ color: labelColor }">
          <!--{{ $t("account.status")[accountHistory.account.status] }}-->
          <v-chip
            small
            outlined
            :color="getColorByStatus({ status: accountHistory.account.status })"
            class="px-2"
          >
            {{ $t("account.status")[accountHistory.account.status] }}
          </v-chip>
          <div class="text-center">
            <span
              v-if="accountHistory.account.errorCode > 1"
              class="red--text text--lighten-3"
            >
              {{ accountHistory.account.errorCode }}
            </span>
          </div>
        </div>
      </v-col>
      <v-col sm="4" md="2" lg="1">
        <a
          :href="accountHistory.account.fullUrl"
          @click.prevent="redirect(accountHistory.account.fullUrl)"
          target="_blank"
          class="grey--text text--darken-4 text-decoration-none"
        >
          <span class="caption break-word">
            {{ accountHistory.account.domain || "-" }}
          </span>
          <v-icon small>mdi-open-in-new</v-icon>
        </a>
      </v-col>
      <v-col sm="3" md="3" lg="1">
        <div class="caption">
          {{ accountHistory.account.fullname || "-" }}
        </div>
      </v-col>
      <v-col sm="2" md="2" lg="1">
        <span class="caption"
          >{{ $t("account.attribute.type") }}
          {{ accountHistory.account.type }}</span
        ><br />
        <span class="caption"
          >{{ $t("account.stage") }} {{ accountHistory.account.stage }}</span
        >
      </v-col>
      <v-col sm="5" md="6" lg="3">
        <v-row
          v-for="(attr, i) in accountHistory.attributes"
          :key="i"
          class="flex-nowrap"
        >
          <v-col sm="6" md="5" lg="5" class="tiny-col">
            <span class="caption"
              >{{
                $t(`account.attribute.${toCamelCase(attr.attribute)}`)
              }}:</span
            >
          </v-col>
          <v-col class="tiny-col">
            <p>
              <span class="caption break-word">
                {{
                  attr.oldValue
                    ? prepareAttributeValue(attr.attribute, attr.oldValue)
                    : "empty"
                }}
              </span>
              <v-icon small>mdi-chevron-right</v-icon>
              <span class="caption break-word">
                {{
                  attr.newValue
                    ? prepareAttributeValue(attr.attribute, attr.newValue)
                    : "empty"
                }}
              </span>
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col sm="2" md="3" lg="1">
        <div class="caption">
          {{
            accountHistory.initiatorType === "api"
              ? "Social"
              : accountHistory.initiator.username
          }}
        </div>
      </v-col>
      <v-col sm="3" md="3" lg="1">
        <div class="caption">{{ accountHistory.reportDate }}</div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import { camelCase } from "lodash";

export default {
  name: "AccountHistoryItem",
  props: {
    accountHistory: Object,
  },
  computed: {
    ...mapGetters("account/donor", [
      "getColorByStatus",
      "getSymbolByGender",
      "getAbrByLanguage",
    ]),
    targetIcon: function () {
      return this.accountHistory.target === "vkontakte"
        ? "alpha-b-box"
        : this.accountHistory.target;
    },
    labelColor: function () {
      const color = this.getColorByStatus({
        status: this.accountHistory.account.status,
      });
      return color;
    },
  },
  methods: {
    toCamelCase: camelCase,
    prepareAttributeValue: function (attribute, value) {
      let result = value;
      switch (attribute) {
        case "language": {
          result = this.getAbrByLanguage({ language: value });
          break;
        }
        case "gender": {
          result = this.getSymbolByGender({ gender: value });
          break;
        }
      }
      return result;
    },
    redirect: function (url) {
      window.open(url, "_blank").focus();
    },
  },
};
</script>

<style scoped>
.break-word {
  word-break: break-all;
}
p {
  margin: 0;
  display: inline-flex;
}
.tiny-col {
  line-height: 0;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
}
</style>
