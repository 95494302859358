<template>
  <div class="about">
    <v-row>
      <v-col>
        <h1>{{ $t("account.historiesTitle") }}</h1>
      </v-col>
      <v-col class="text-end">
        <v-btn
          small
          color="primary"
          class="ml-2"
          :loading="exportLoading"
          :disabled="exportLoading || exportDisabled"
          @click="exportItems"
        >
          {{ $t("account.exportHistories") }}
          <template v-slot:loader>
            <span>{{ $t("account.exportProcess") }}</span>
          </template>
        </v-btn>
        <br />
        <span v-if="exportDisabled" class="caption">{{
          $t("account.exportCountError", { count: maxExportCount })
        }}</span>
      </v-col>
    </v-row>
    <account-history-search
      v-if="pageReady"
      :labels="attributeLabels"
    ></account-history-search>
    <v-divider></v-divider>

    <div>
      <!-- Data loader -->
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <div>
        <!--    Data table     -->
        <v-data-iterator
          hide-default-footer
          disable-filtering
          disable-pagination
          disable-sort
          :items="items"
          :no-data-text="$t('no-data')"
        >
          <template v-slot:header>
            <!--    Table header begin -->
            <div
              v-if="totalCount > 0"
              class="d-sm-none d-md-none d-lg-block px-4 py-0 pb-0"
            >
              <v-row class="grey--text body-2">
                <v-col sm="2" md="2" lg="2" class="align-self-end">
                  {{ $t("account.attribute.platform") }}/{{
                    $t("account.attribute.userId")
                  }}
                </v-col>
                <v-col lg="2" class="align-self-end text-center">
                  {{ $t("account.attribute.status") }}
                </v-col>
                <v-col lg="1" class="align-self-end">
                  {{ $t("account.attribute.domain") }}
                </v-col>
                <v-col lg="1" class="align-self-end">
                  {{ $t("account.attribute.fullname") }}
                </v-col>
                <v-col lg="1" class="align-self-end break-word">
                  {{ $t("account.attribute.currentCharacteristics") }}
                </v-col>
                <v-col
                  cols="2"
                  sm="12"
                  md="8"
                  lg="3"
                  class="align-self-end flex-nowrap"
                >
                  <v-row class="flex-nowrap">
                    <v-col sm="6" md="5" lg="5" class="align-self-end">
                      {{ $t("account.attribute.changedAttribute") }}
                    </v-col>
                    <v-col sm="6" md="7" lg="7" class="align-self-end">
                      {{ $t("account.attribute.changedAttributeValue") }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col lg="1" class="align-self-end">
                  {{ $t("source.attribute.name") }}
                </v-col>
                <v-col lg="1" class="align-self-end">
                  {{ $t("account.attribute.changedAt") }}
                </v-col>
              </v-row>
            </div>
            <!--    Table header end -->
          </template>
          <template v-slot:default="props">
            <account-history-item
              v-for="(item, i) in props.items"
              :key="i"
              :accountHistory="item"
            ></account-history-item>
          </template>
        </v-data-iterator>
        <!--    Table pagination -->
        <div class="text-right">
          <span v-if="totalCount > 0" class="ml-2 body-2">
            {{
              $t("paginationSummary", {
                from: offset + 1,
                to: maxItem,
                totalCount: totalCount,
              })
            }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="pageCount > 1" class="text-center pt-6">
      <v-pagination
        v-model="pagination.page"
        :length="pageCount"
        :total-visible="9"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BaseListPage from "@/views/base/BaseListPage";
import {
  EXPORT_ACCOUNT_HISTORIES,
  FETCH_ACCOUNT_HISTORIES,
  SET_FILTER,
} from "@/store/actions/account-histories";
import { mapState, mapGetters } from "vuex";
import AccountHistoryItem from "@/views/account-histories/parts/AccountHistoryItem";
import AccountHistorySearch from "@/views/account-histories/parts/AccountHistorySearch";
import { FETCH_SERVICES } from "@/store/actions/service";
import { multipleFilters } from "@/store/modules/account/donor/state";
import { defaultFilters } from "@/store/modules/account/histories/state";

export default {
  name: "AccountHistories",
  extends: BaseListPage,
  components: { AccountHistoryItem, AccountHistorySearch },
  computed: {
    ...mapState("account/histories", ["filters", "pagination", "emptyFilters"]),
    ...mapGetters("account/histories", [
      "offset",
      "itemsPerPage",
      "totalCount",
      "pageCount",
      "items",
      "formattedFilters",
    ]),
    exportDisabled: function () {
      return this.totalCount > this.maxExportCount;
    },
  },
  data() {
    return {
      //Whether export in process
      exportLoading: false,
      attributeLabels: {
        scope: this.$t("account.attribute.platform"),
        reportChangedRange: this.$t("account.attribute.reportChangedRange"),
        reportDateFrom: this.$t("account.attribute.reportDateFrom"),
        reportDateTo: this.$t("account.attribute.reportDateTo"),
        gobellsId: this.$t("account.attribute.gobellsId"),
        userId: this.$t("account.attribute.userId"),
        attribute: this.$t("account.attribute.changedAttribute"),
        oldValue: this.$t("account.attribute.oldValue"),
        newValue: this.$t("account.attribute.newValue"),
        currentDomain: this.$t("account.attribute.domain"),
        currentStatus: this.$t("account.attribute.status"),
        currentStage: this.$t("account.attribute.stage"),
        currentType: this.$t("account.attribute.type"),
      },
      maxExportCount: 75000,
      defaultFilters: _.merge({}, defaultFilters),
      multipleFilters,
    };
  },
  methods: {
    loadItems: function () {
      this.dataLoading = true;
      this.$store
        .dispatch("account/histories/" + FETCH_ACCOUNT_HISTORIES)
        .then(
          () => {
            this.dataLoading = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    exportItems: function () {
      this.$store
        .dispatch("account/histories/" + EXPORT_ACCOUNT_HISTORIES)
        .then(
          (payload) => {
            const { data } = payload;
            if (data.taskHash) {
              this.$notify({
                group: "messages",
                title: this.$t("account.export"),
                text: this.$t("account.exportStarted"),
              });
              this.$socket.emit("subscribe", {
                channel: "export",
                subChannel: data.taskHash,
              });
              this.exportLoading = true;
            } else {
              this.$notify({
                group: "error",
                type: "error",
                title: this.$t("account.export"),
                text: this.$t("account.exportFailed"),
              });
            }
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    loadServices: function () {
      this.dataLoading = true;
      this.$store
        .dispatch("service/" + FETCH_SERVICES, null, { root: true })
        .catch(() => {});
    },
    applyFilters: function (data) {
      this.$store.commit("account/histories/" + SET_FILTER, data.data);
    },
  },
  mounted() {
    if (!this.applyRouteParams()) {
      this.loadItems();
    }
    this.loadServices();
  },
  sockets: {
    export: function (msg) {
      const { payload } = msg;
      if (payload && payload.status === "failed") {
        this.$notify({
          group: "error",
          type: "error",
          title: this.$t("account.export"),
          text: this.$t("account.exportFailed"),
        });
      }
      this.exportLoading = false;
    },
  },
};
</script>

<style scoped>
.break-word {
  word-break: break-all;
}
</style>
