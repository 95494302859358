<template>
  <div class="pb-2 pt-4">
    <v-row v-if="filtersReady">
      <component
        :is="item"
        v-for="(item, index) in accountHistoriesFilters"
        v-model="filters[item]"
        :key="index"
        :label="attributeLabels[item]"
        :allowEmpty="true"
        @remove="removeFilter(item)"
      ></component>
      <v-col
        :sm="isEmpty(availableFilters) ? 6 : 12"
        :md="isEmpty(availableFilters) ? 4 : 8"
        :lg="isEmpty(availableFilters) ? 2 : 4"
        class="align-self-center"
      >
        <v-menu
          v-if="!isEmpty(availableFilters)"
          top
          :close-on-content-click="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              outlined
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              <v-icon x-small>mdi-plus</v-icon>
              {{ $t("addFilter") }}
            </v-btn>
          </template>

          <v-list width="250px">
            <v-list-item-group>
              <v-virtual-scroll
                height="200"
                :itemHeight="40"
                :items="availableFilters"
              >
                <template v-slot:default="{ item }">
                  <v-list-item>
                    <v-list-item-title @click="addFilter(item.key)">{{
                      item.label
                    }}</v-list-item-title>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-btn x-small outlined color="red" dark @click="resetFilters">
          {{ $t("resetFilters") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapState } from "vuex";

import { availableAccountHistoriesFilters } from "@/store/modules/persistentStorage/state";
import { emptyFilters } from "@/store/modules/account/histories/state";

import {
  SET_ACCOUNT_HISTORIES_FILTER,
  SET_DEFAULT_ACCOUNT_HISTORIES_FILTER,
  REMOVE_ACCOUNT_HISTORIES_FILTER,
  ADD_ACCOUNT_HISTORIES_FILTER,
} from "@/store/actions/persistentStorage";

import { SET_FILTER } from "@/store/actions/account-histories";

import TextSearch from "@/views/search-parts/TextSearch";
import DateSearch from "@/views/search-parts/DateSearch";
import DateRangeSearch from "@/views/search-parts/DateRangeSearch";
import TypeSearch from "@/views/search-parts/TypeSearch";
import StageSearch from "@/views/search-parts/StageSearch";
import StatusSearch from "@/views/search-parts/StatusSearch";
import AttributeSearch from "@/views/search-parts/AttributeSearch";
import ScopeSearch from "@/views/search-parts/ScopeSearch";

export default {
  name: "AccountHistorySearch",
  components: {
    scope: ScopeSearch,
    reportChangedRange: DateRangeSearch,
    reportDateFrom: DateSearch,
    reportDateTo: DateSearch,
    gobellsId: TextSearch,
    userId: TextSearch,
    attribute: AttributeSearch,
    oldValue: TextSearch,
    newValue: TextSearch,
    currentDomain: TextSearch,
    currentType: TypeSearch,
    currentStage: StageSearch,
    currentStatus: StatusSearch,
  },
  props: {
    labels: Object,
  },
  data: function () {
    return {
      filtersReady: false,
      attributeLabels: this.labels,
    };
  },
  computed: {
    ...mapGetters("service", ["items"]),
    ...mapGetters("account/histories", ["formattedFilters", "filters"]),
    ...mapState("persistentStorage", ["accountHistoriesFilters"]),
    availableFilters: function () {
      let vm = this;
      let result = [];

      const filtersToSelect = _.difference(
        availableAccountHistoriesFilters,
        this.accountHistoriesFilters
      );

      filtersToSelect.forEach(function (filter) {
        result.push({
          key: filter,
          label: _.get(vm.attributeLabels, filter),
        });
      });
      return result;
    },
    queryHint: function () {
      return (
        this.attributeLabels.userId +
        "/" +
        this.attributeLabels.domain +
        "/" +
        this.attributeLabels.url
      );
    },
  },
  watch: {
    filters: {
      handler: function () {
        const range = this.filters.reportChangedRange;
        if (range && range.length === 2) {
          this.filters.reportDateFrom = range[0];
          this.filters.reportDateTo = range[1];
        } else {
          this.filters.reportDateFrom = null;
          this.filters.reportDateTo = null;
        }
      },
      deep: true,
    },
    accountHistoriesFilters: {
      handler: function () {
        let query = _.merge({}, this.$route.query);
        query.selectedFilters = this.accountHistoriesFilters;
        this.$router
          .push({ name: this.currentRouteName, query })
          .catch(() => {});
      },
      deep: true,
    },
  },
  methods: {
    isSelectedFilter: function (filter) {
      return this.accountHistoriesFilters.indexOf(filter) > -1;
    },
    addFilter: function (filter) {
      this.$store.commit(
        `persistentStorage/${ADD_ACCOUNT_HISTORIES_FILTER}`,
        filter
      );
    },
    removeFilter: function (filter) {
      this.$store.commit(
        `persistentStorage/${REMOVE_ACCOUNT_HISTORIES_FILTER}`,
        filter
      );
      this.filters[filter] = null;
    },
    isEmpty: function (value) {
      return _.isEmpty(value);
    },
    resetFilters: function () {
      this.$store.commit(
        `persistentStorage/${SET_DEFAULT_ACCOUNT_HISTORIES_FILTER}`
      );
      this.$store.commit(
        "account/histories/" + SET_FILTER,
        _.merge({}, emptyFilters)
      );
    },
  },
  mounted: function () {
    const vm = this;
    if (!_.isEmpty(vm.$route.query) && vm.$route.query["selectedFilters"]) {
      let selectedFilters = vm.$route.query["selectedFilters"];
      if (!(selectedFilters instanceof Array)) {
        selectedFilters = [selectedFilters];
      }
      this.$store.commit(
        `persistentStorage/${SET_ACCOUNT_HISTORIES_FILTER}`,
        _.merge([], selectedFilters)
      );
    }
    if (!_.isEmpty(vm.$route.query)) {
      _.forIn(vm.$route.query, function (value, filter) {
        if (!vm.isSelectedFilter(filter)) {
          vm.addFilter(filter);
        }
      });
    }
    vm.filtersReady = true;
  },
};
</script>

<style scoped>
.theme--light.v-divider {
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.08);
}
</style>
